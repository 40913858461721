import React from 'react';
import { X } from 'react-feather';
import _ from 'lodash';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import FormikInputGroup from '../../components/formik/FormikInputGroup';

const computeTotalMeter = (takaDetails) => {
    if (takaDetails) {
        console.log(takaDetails);
        const totalMeter = takaDetails.reduce((acc, ele) => {
            acc += parseFloat(ele.sendQuantity);
            return acc;
        }, 0);
        return totalMeter;
    }
    return 0;
};

const computeTotalTaka = (takaDetails) => {
    if (takaDetails) {
        console.log(takaDetails, 'in total taka');
        const totalTaka = takaDetails.filter((ele) => {
            return ele.status === 'active';
        });
        console.log(totalTaka);
        return totalTaka.length;
    }
    return 0;
};

const Row = function Row({ formik, index, element, arrayHelpers }) {
    return (
        <>
            <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                <div className="flex gap-4">
                    <X
                        onClick={(e) => {
                            arrayHelpers.remove(index);
                        }}
                    />
                    {index + 1}.
                </div>
            </td>
            <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                <div>{element?.batchNo}</div>
            </td>
            <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                <div>{element?.quality}</div>
            </td>
            <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                <div>
                    <FormikInputGroup
                        name={`batches.${index}.totalTaka`}
                        formik={formik}
                        readOnly
                        value={computeTotalTaka(
                            formik?.values?.batches?.[index]?.takaDetails
                        )}
                    />
                </div>
            </td>
            <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                <div>
                    <FormikInputGroup
                        name={`batches.${index}.totalMeter`}
                        formik={formik}
                        value={computeTotalMeter(
                            formik?.values?.batches?.[index]?.takaDetails
                        )}
                        readOnly
                    />
                </div>
            </td>
        </>
    );
};

export function MillDispatchRow({ formik, index, element, arrayHelpers }) {
    return (
        <>
            <Row
                formik={formik}
                index={index}
                element={element}
                arrayHelpers={arrayHelpers}
            />
        </>
    );
}
