import { FieldArray, FormikProvider, useFormik } from 'formik';
import ModalBasic from '../../components/infrastructure/Modals/ModalBasic';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import { XSquare } from 'react-feather';
import * as Yup from 'yup';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

const AddQuantityTaka = (props) => {
    const { isViewTaka, setViewTaka, formik, editData, setEditData, rate } =
        props;

    const formikTaka = useFormik({
        enableReinitialize: true,
        initialValues: {
            takaDetail: editData?.takaDetail ? editData?.takaDetail : [''],
            rate: rate,
        },
        validationSchema: Yup.object({
            rate: Yup.string().required(),
            takaDetail: Yup.array()
                .of(
                    Yup.number()
                        .typeError('Must be a number')
                        .min(0, 'Value must be greater than or equal to 0')
                        .required('Value is required')
                )
                .required('Taka Detail is required')
                .test(
                    'no-empty',
                    'Array should not contain empty values',
                    (arr) => arr && arr.every((item) => item !== '')
                ),
        }),
        onSubmit: async (values) => {
            let takaDetail = [...formik.values.poDetails];
            const finalTakaDetails = values?.takaDetail?.filter((ele) => {
                return ele !== '' && typeof ele !== 'undefined' && ele !== null;
            });
            if (finalTakaDetails?.length === 0) {
                alert('No valid Values');
                return;
            }

            console.log(finalTakaDetails, 'final data');
            let takaLength = finalTakaDetails?.length;
            let poDetail = {
                quality: editData?.quality
                    ? editData?.quality
                    : formik.values.quality.split('/')[0],
                qualityName: editData?.qualityName
                    ? editData?.qualityName
                    : formik.values.qualityName,
                hsnCode: editData?.hasnCode
                    ? parseInt(editData?.hsnCode)
                    : parseInt(formik.values.quality.split('/')[1]),
                taka: takaLength,
                meter: finalTakaDetails.reduce(
                    (acc, item) => acc + Number(parseFloat(item).toFixed(2)),
                    0
                ),
                takaDetail: finalTakaDetails,
                rate: values.rate,
            };
            if (editData && Object.keys(editData)?.length > 0) {
                takaDetail[editData?.curIndex] = poDetail;
            } else {
                takaDetail.push(poDetail);
            }

            console.log(finalTakaDetails);
            formik.setFieldValue('poDetails', takaDetail);
            formikTaka.setFieldValue('poDetails', []);
            setViewTaka(false);
        },
    });

    const handleKeyDown = (event, arrayHelpers) => {
        event.stopPropagation();
        if (event.key === 'Enter') {
            arrayHelpers.push(null);
        }
    };

    useEffect(() => {
        if (!isViewTaka) {
            console.log('setting formik details');
            formikTaka.setFieldValue('takaDetail', ['']);
            setEditData(null);
        }
    }, [isViewTaka]);

    return (
        <ModalBasic
            modalOpen={isViewTaka}
            setModalOpen={setViewTaka}
            title={'Add Taka'}
        >
            <div className="p-4">
                <form onSubmit={formikTaka.handleSubmit}>
                    <FormikInputGroup
                        name={'rate'}
                        formik={formikTaka}
                        label="Rate"
                        type="number"
                    />
                    <div className="flex gap-2">
                        <span>
                            Total Quantity :{' '}
                            {formikTaka?.values?.takaDetail?.length
                                ? formikTaka.values.takaDetail.filter((ele) => {
                                      return (
                                          ele !== '' &&
                                          typeof ele !== 'undefined' &&
                                          ele !== null
                                      );
                                  }).length
                                : 0}
                        </span>
                        <span>
                            Total Meter :{' '}
                            {formikTaka?.values?.takaDetail?.length
                                ? formikTaka.values.takaDetail.reduce(
                                      (acc, ele) => {
                                          if (
                                              ele !== '' &&
                                              typeof ele !== 'undefined' &&
                                              ele !== null
                                          ) {
                                              acc =
                                                  acc +
                                                  Number(
                                                      parseFloat(ele).toFixed(2)
                                                  );
                                          }
                                          return acc;
                                      },
                                      0
                                  )
                                : 0}
                        </span>
                    </div>
                    <h1 className="mb-2 mt-2 font-bold">Taka Details</h1>
                    <FormikProvider value={formikTaka}>
                        <FieldArray
                            name="takaDetail"
                            render={(arrayHelpers) => (
                                <div className="border border-gray-400 p-3 rounded-lg">
                                    {formikTaka.values?.takaDetail?.map(
                                        (ele, index) => (
                                            <div
                                                className=" items-center flex gap-2 my-4 relative"
                                                key={index}
                                            >
                                                <span>{index + 1}</span>
                                                <XSquare
                                                    className="absolute top-0 right-0"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        arrayHelpers.remove(
                                                            index
                                                        );
                                                    }}
                                                />
                                                <div className="w-full">
                                                    <FormikInputGroup
                                                        formik={formikTaka}
                                                        type="number"
                                                        step
                                                        name={`takaDetail.${index}`}
                                                        onKeyDown={(e) =>
                                                            handleKeyDown(
                                                                e,
                                                                arrayHelpers
                                                            )
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        )
                                    )}
                                    <div>
                                        <PrimaryButton
                                            onClick={() =>
                                                arrayHelpers.push(null)
                                            }
                                        >
                                            Add more
                                        </PrimaryButton>
                                    </div>
                                </div>
                            )}
                        />
                    </FormikProvider>
                    <div className="mt-2">
                        <PrimaryButton
                            type="button"
                            onClick={formikTaka.handleSubmit}
                        >
                            Submit
                        </PrimaryButton>
                    </div>
                </form>
            </div>
        </ModalBasic>
    );
};

export default AddQuantityTaka;
