import {
    Briefcase,
    Clipboard,
    DollarSign,
    FileText,
    Folder,
    MapPin,
    Monitor,
    UserPlus,
} from 'react-feather';
import SidebarGroup from '../../components/infrastructure/SidebarGroup';
import SidebarSingle from '../../components/infrastructure/SidebarSingle';
import SidebarTopText from '../../components/infrastructure/SidebarTopText';

const SidebarLinks = ({ sidebarOpen, sidebarExpanded, setSidebarExpanded }) => {
    return (
        <ul className="mt-3">
            {/* Dashboard */}
            <SidebarTopText text="Application" sidebarOpen={sidebarOpen} />
            <SidebarSingle
                allowedRoles={['admin']}
                activePath="/"
                name="Dashboard"
                Icon={<Monitor />}
                sidebarOpen={sidebarOpen}
            />
            <SidebarSingle
                allowedRoles={['admin']}
                activePath="/interestCalculation"
                name="Interest Calculation"
                Icon={<FileText />}
                sidebarOpen={sidebarOpen}
            />
            <SidebarSingle
                allowedRoles={['admin']}
                activePath="/itemsInventory"
                name="Items Inventory"
                Icon={<FileText />}
                sidebarOpen={sidebarOpen}
            />
            <SidebarGroup
                links={[
                    {
                        label: 'Add User',
                        link: '/addUser',
                        allowedRoles: ['admin'],
                    },
                    {
                        label: 'Edit User',
                        link: '/editUser',
                        allowedRoles: ['admin'],
                    },
                    {
                        label: 'View User',
                        link: '/viewUser',
                        allowedRoles: ['admin'],
                    },
                    // {
                    //     label: 'Add Organization',
                    //     link: '/addOrganization',
                    //     allowedRoles: ['admin'],
                    // },
                    // {
                    //     label: 'View Organization',
                    //     link: '/viewOrganization',
                    //     allowedRoles: ['admin'],
                    // },
                ]}
                heading="User"
                Icon={<UserPlus />}
                sidebarExpanded={sidebarExpanded}
                setSidebarExpanded={setSidebarExpanded}
            />
            <SidebarGroup
                links={[
                    // {
                    //     label: 'Yarn Grade',
                    //     link: '/yarnGrade',
                    //     allowedRoles: ['admin'],
                    // },
                    // {
                    //     label: 'Reminder Subject',
                    //     link: '/reminderSubject',
                    //     allowedRoles: ['admin'],
                    // },
                    {
                        label: 'Quality',
                        link: '/createQuality',
                        allowedRoles: ['admin'],
                    },
                    // {
                    //     label: 'Payment Term',
                    //     link: '/createPaymentTerm',
                    //     allowedRoles: ['admin'],
                    // },
                    // {
                    //     label: 'View Qualities',
                    //     link: '/ViewQualities',
                    //     allowedRoles: ['admin'],
                    // },
                    // {
                    //     label: 'Fetch All Vendors',
                    //     link: '/fetch-all-vendors',
                    //     allowedRoles: ['admin'],
                    // },
                ]}
                heading="Master"
                Icon={<Folder />}
                sidebarExpanded={sidebarExpanded}
                setSidebarExpanded={setSidebarExpanded}
            />
            <SidebarSingle
                Icon={<FileText />}
                allowedRoles={['admin']}
                sidebarOpen={sidebarOpen}
                activePath="/view-purchase-order"
                name="View Purchase Order"
            />
            <SidebarSingle
                Icon={<FileText />}
                allowedRoles={['admin']}
                sidebarOpen={sidebarOpen}
                activePath="/mill-dispatch"
                name="Mill Dispatch"
            />
            <SidebarGroup
                links={[
                    {
                        label: 'Add Location',
                        link: '/addLocation',
                        allowedRoles: ['admin'],
                    },
                    {
                        label: 'View Location',
                        link: '/viewLocation',
                        allowedRoles: ['admin'],
                    },
                    {
                        label: 'Assign Location',
                        link: '/assignLocation',
                        allowedRoles: ['admin'],
                    },
                ]}
                heading="Locations"
                Icon={<MapPin />}
                sidebarExpanded={sidebarExpanded}
                setSidebarExpanded={setSidebarExpanded}
            />{' '}
            <SidebarSingle
                allowedRoles={['admin']}
                activePath="/mediaLibrary"
                name="Media Library"
                Icon={<Briefcase />}
                sidebarOpen={sidebarOpen}
            />
            {/* <SidebarSingle
                Icon={<FileText />}
                allowedRoles={['admin']}
                sidebarOpen={sidebarOpen}
                activePath="/view-vendors"
                name="View Vendors"
            />
            <SidebarSingle
                Icon={<FileText />}
                allowedRoles={['admin']}
                sidebarOpen={sidebarOpen}
                activePath="/view-customer"
                name="View Customers"
            />
            <SidebarSingle
                Icon={<FileText />}
                allowedRoles={['admin']}
                sidebarOpen={sidebarOpen}
                activePath="/customer-preference"
                name="Customer preference"
            />
            <SidebarSingle
                Icon={<FileText />}
                allowedRoles={['admin']}
                sidebarOpen={sidebarOpen}
                activePath="/follow-up"
                name="Follow Up"
            />
            <SidebarSingle
                Icon={<FileText />}
                allowedRoles={['admin']}
                sidebarOpen={sidebarOpen}
                activePath="/pendingOrderList"
                name="Pending Order List"
            />
         
            <SidebarSingle
                Icon={<Clipboard />}
                allowedRoles={['admin']}
                sidebarOpen={sidebarOpen}
                activePath="/create-purchase-order"
                name="Purchase Order"
            />
           
            <SidebarSingle
                Icon={<FileText />}
                allowedRoles={['admin']}
                sidebarOpen={sidebarOpen}
                activePath="/view-previous-order"
                name="View Previous Order"
            />
            <SidebarGroup
                links={[
                    {
                        label: 'Yarn Grade',
                        link: '/yarnGrade',
                        allowedRoles: ['admin'],
                    },
                    {
                        label: 'Reminder Subject',
                        link: '/reminderSubject',
                        allowedRoles: ['admin'],
                    },
                    {
                        label: 'Quality',
                        link: '/createQuality',
                        allowedRoles: ['admin'],
                    },
                    {
                        label: 'Payment Term',
                        link: '/createPaymentTerm',
                        allowedRoles: ['admin'],
                    },
                    {
                        label: 'View Qualities',
                        link: '/ViewQualities',
                        allowedRoles: ['admin'],
                    },
                    {
                        label: 'Fetch All Vendors',
                        link: '/fetch-all-vendors',
                        allowedRoles: ['admin'],
                    },
                ]}
                heading="Master"
                Icon={<Folder />}
                sidebarExpanded={sidebarExpanded}
                setSidebarExpanded={setSidebarExpanded}
            />
            <SidebarGroup
                links={[
                    {
                        label: 'Attributes',
                        link: '/AddAttribute',
                        allowedRoles: ['admin'],
                    },
                ]}
                heading="Attributes"
                Icon={<Folder />}
                sidebarExpanded={sidebarExpanded}
                setSidebarExpanded={setSidebarExpanded}
            />
            <SidebarSingle
                Icon={<FileText />}
                allowedRoles={['admin']}
                sidebarOpen={sidebarOpen}
                activePath="/podelivery"
                name="Create Complain"
            />
            <SidebarSingle
                Icon={<FileText />}
                allowedRoles={['admin']}
                sidebarOpen={sidebarOpen}
                activePath="/view-complain"
                name="Complaint Summary"
            />
            <SidebarGroup
                links={[
                    {
                        label: ' Trigger Automation',
                        link: '/trigger-automation',
                        allowedRoles: ['admin'],
                    },
                    {
                        label: 'Create Invoice',
                        link: '/create-invoice',
                        allowedRoles: ['admin'],
                    },
                    {
                        label: 'View Invoices',
                        link: '/view-invoice',
                        allowedRoles: ['admin'],
                    },
                ]}
                heading="Invoice"
                Icon={<DollarSign />}
                sidebarExpanded={sidebarExpanded}
                setSidebarExpanded={setSidebarExpanded}
            />

            <SidebarSingle
                Icon={<FileText />}
                allowedRoles={['admin']}
                sidebarOpen={sidebarOpen}
                activePath="/ultraDiagnosis"
                name="Diagnosis Projects"
            />
            <SidebarSingle
                Icon={<FileText />}
                allowedRoles={['admin']}
                sidebarOpen={sidebarOpen}
                activePath="/report"
                name="Report"
            />
            <SidebarSingle
                Icon={<FileText />}
                allowedRoles={['admin']}
                sidebarOpen={sidebarOpen}
                activePath="/viewNotice"
                name="Notice"
            />
            <SidebarSingle
                Icon={<FileText />}
                allowedRoles={['admin']}
                sidebarOpen={sidebarOpen}
                activePath="/view-notification"
                name="Notifications"
            />
            <SidebarSingle
                Icon={<FileText />}
                allowedRoles={['admin']}
                sidebarOpen={sidebarOpen}
                activePath="/view-sucess-notification"
                name="Success Notifications"
            /> */}
        </ul>
    );
};

export default SidebarLinks;
