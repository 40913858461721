import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';

const FormikInputGroup = ({
    formik,
    label = '',
    required = false,
    name,
    fullWidth,
    onChange = null,
    ...props
}) => {
    const [value, setValue] = useState(
        props.value ? props.value : _.at(formik.values, name)[0]
    );
    const [cursorPosition, setCursorPosition] = useState(null);
    const ref = useRef();

    const onChangeFunction = onChange
        ? onChange
        : (e) => {
              formik.setFieldValue(name, e.target.value);
              setCursorPosition(e.target.selectionStart);
          };

    useEffect(() => {
        if (!_.isNil(props.value) || !_.isNil(_.at(formik.values, name)[0])) {
            //when props value is 0 this is not getting triggered
            formik.setFieldValue(
                name,
                !_.isNil(props.value)
                    ? props.value
                    : _.at(formik.values, name)[0]
            );
            setValue(
                !_.isNil(props.value)
                    ? props.value
                    : _.at(formik.values, name)[0]
            );
        }
    }, [_.at(formik.values, name)[0], props.value]);

    useEffect(() => {
        const input = ref.current;

        if (input && ['text'].includes(input?.type))
            setTimeout(() => {
                input.setSelectionRange(cursorPosition, cursorPosition);
            }, 5);
    }, [ref, cursorPosition, _.at(formik.values, name)[0], props.value]);

    return (
        <div className={fullWidth ? 'w-full' : ''}>
            <label className="block text-sm font-medium mb-1" htmlFor={name}>
                {label} {required && <span className="text-rose-500">*</span>}
            </label>
            <input
                ref={ref}
                type="text"
                value={value}
                onChange={onChangeFunction}
                name={name}
                // onBlur={formik.handleBlur}
                className="w-full form-input"
                {...props}
            />
            {_.at(formik.errors, name)[0] && _.at(formik.touched, name)[0] ? (
                <p className="text-xs text-red-500">
                    {_.at(formik.errors, name)[0]}
                </p>
            ) : null}
        </div>
    );
};

export default FormikInputGroup;
